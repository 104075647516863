/***********************************************************************
************ Author:    Christian KEMGANG NGUESSOP *********************
************ Version:    1.0.0                      ********************
***********************************************************************/

import styled from "styled-components";

export const ExperienceContainer = styled.div`
    background: linear-gradient(to bottom, #2f2360 -13%, #f5f5f5 105%);
    display: flex;
    flex-direction: column;
    border: 3px solid transparent;
    margin: 5%;
    padding: 6%;
    @media screen and (max-width: 820px) {
        align-items: center;
    }  
`;

export const ExperienceWrapper = styled.div`
    background: linear-gradient(to left, #f5f5f5 30%, #ffffff 77%);
    text-align: center;
    margin-top: -6%;
    margin-bottom: 5%;
    border: 3px solid bisque;
    border-radius: 25px;
    @media screen and (max-width: 820px){
      flex-direction: column;
    }
`;

export const Title = styled.h1`
    color: #2f2360;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.5rem;
`;
export const Title2 = styled.h2`
    color: #2f2360;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
`;

export const ExperienceItems = styled.div`
    display: flex;
    justify-content: center;
    background: linear-gradient(to bottom, #2f2360 -13%, #f5f5f5 105%);
    border: 2px solid transparent;
    margin-top: -3%;
    margin-bottom: -5%;
    padding: 15px;
    border-radius: 25px;
    @media screen and (max-width: 820px){
      flex-direction: column;
      
    }
`;

export const ExperienceItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    width: 280px;
    border-radius: 25px;
    background: linear-gradient(to left, #f5f5f5 30%, #ffffff 77%);
    @media screen and (max-width: 420px){
        margin: 0;
        width: 100%;
    }
`;

export const Items = styled.div`
    font-weight: bold;
    place-self: start;
    padding: 0px;
    margin-left: 0.8rem;
    margin-bottom: 0.9rem;
    color: #2f2360;
`;

export const Item = styled.ul`
    color: #2f2360;
    padding-left: 0px;
    margin: 0px;
`;

export const ItemContent = styled.li`
    font-size: 0.9rem;
    color: #2f2360;
    font-style: italic;
`;

export const Close = styled.button`
    place-self: end;
    color: #2f2360;
    font-weight: bold;
    position: sticky;
    border-radius: 5px;
    &:hover {
        background-color: #2f2360;
        color: white;
        cursor: pointer;
    }
    &:active {
        opacity: 0.8;
    };
`;

export const Open = styled.button`
    place-self: center;
    color: #2f2360;
    font-weight: bold;
    position: sticky;
    border-radius: 5px;
    &:hover {
        background-color: #2f2360;
        color: white;
        cursor: pointer;
    }
    &:active {
        opacity: 0.8;
    };
`;
