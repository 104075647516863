/***********************************************************************
************ Author:    Christian KEMGANG NGUESSOP *********************
************ Version:    1.0.0                      ********************
***********************************************************************/

import { apiSlice } from "../api/apiSlice";

export const contactsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        addContact: builder.mutation({
            query: (body) => ({
                url: '/contacts',
                method: 'POST',
                body,
            }),
            invalidatesTags: [
                { type: 'Post', id: "DataContact" }
            ]
        }),
    })
});

export const { useAddContactMutation } = contactsApiSlice