/***********************************************************************
************ Author:    Christian KEMGANG NGUESSOP *********************
************ Version:    1.0.0                      ********************
***********************************************************************/

import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const professionalsAdapter = createEntityAdapter()

const initialState = professionalsAdapter.getInitialState()

export const professionalsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getProfessionals: builder.query({
            query: () => '/professionals',
            transformResponse: responseData => {
                return professionalsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => [
                { type: "Professionals", id: "DataProfessionals" },
                ...result.ids.map(id => ({ type: "Professionals", id }))
            ]
        })
    })
});

export const { useGetProfessionalsQuery } = professionalsApiSlice