/***********************************************************************
************ Author:    Christian KEMGANG NGUESSOP *********************
************ Version:    1.0.0                      ********************
***********************************************************************/

import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  background: linear-gradient(to bottom, #2f2360 -13%, #f5f5f5 105%);
`;
