/***********************************************************************
************ Author:    Christian KEMGANG NGUESSOP *********************
************ Version:    1.0.0                      ********************
***********************************************************************/

import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const personalsAdapter = createEntityAdapter()

const initialState = personalsAdapter.getInitialState()

export const personalsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getPersonals: builder.query({
            query: () => '/personals',
            transformResponse: responseData => {
                return personalsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => [
                { type: "Personals", id: "DataPersonals" },
                ...result.ids.map(id => ({ type: "Personals", id }))
            ]
        })
    })
});

export const { useGetPersonalsQuery } = personalsApiSlice