/***********************************************************************
************ Author:    Christian KEMGANG NGUESSOP *********************
************ Version:    1.0.0                      ********************
***********************************************************************/

import React from 'react';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import { useAddContactMutation } from '../../features/contacts/contactsSlice';
import { BtnPrimary } from '../../styles/Button.Styled';
import {
    ButtonContent,
    ContactContainer, ContactError,
    ContactFieldset, ContactForm,
    ContactInput, ContactTextarea,
    ContactWapper, Label, H2, LabelItem
} from '../../styles/styledContact/Contact.Styled';
//const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const Contact = () => {

    const navigate = useNavigate();
    const [addContact] = useAddContactMutation();

    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = async (data, e) => {
        e.preventDefault();

        if (data) {
            const firstname = data.firstname;
            const lastname = data.lastname;
            const email = data.email;
            const gender = data.gender;
            const message = data.message;

            try {
                await addContact({ firstname, lastname, email, gender, message });

                toast("Message send successfully!", {
                    icon: '🤩',
                });
                e.target.reset();
                navigate("/");
            } catch (err) {
                toast.error("Failed to send the message!", {
                    icon: '🤦🏾‍♂️',
                    position: "bottom-left",
                });
            }
        } else {
            toast.error("Failed to send, please retry later!!!", {
                icon: '🤦🏾‍♂️',
                position: "bottom-left",
            });
        }
    };

    return (
        <ContactContainer>
            <pre>{JSON.stringify(register)}</pre>
            <ContactWapper>
                <H2>SEND ME MESSAGE</H2>
                <ContactForm onSubmit={handleSubmit(onSubmit)}>
                    <Label>
                        <label htmlFor='firstname' />
                        <LabelItem>
                            <ContactInput type="text"
                                name="firstname"
                                placeholder="First name"
                                {...register("firstname", {
                                    required: "First name is required!",
                                    maxLength: 20,
                                    pattern: {
                                        value: /^[a-zA-Z]*$/,
                                        message: "Invalid name",
                                    }
                                })} />
                            {errors.firstname && (<ContactError><small>{errors.firstname.message}</small></ContactError>)}
                        </LabelItem>
                        <label htmlFor='lastname' />
                        <LabelItem>
                            <ContactInput type="text"
                                name="lastname"
                                placeholder="Last name"
                                {...register("lastname", {
                                    required: "Last name is required!",
                                    maxLength: 20,
                                    pattern: {
                                        value: /^[a-zA-Z]*$/,
                                        message: "Invalid name",
                                    }
                                })} />
                            {errors.lastname && (<ContactError><small>{errors.lastname.message}</small></ContactError>)}
                        </LabelItem>
                    </Label>
                    <label htmlFor='email' />
                    <ContactInput type="email"
                        name="email"
                        placeholder="Email"
                        {...register("email", {
                            required: "Email is Required!",
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                            }
                        })} />
                    {errors.email && (<ContactError ><small>{errors.email.message}</small></ContactError>)}
                    <ContactFieldset>
                        {errors.gender && (<ContactError><small>{errors.gender.message}</small></ContactError>)}
                        <legend>Gender</legend>
                        <label>
                            <input type="radio"
                                name="gender" value="F"
                                {...register("gender", { required: "Gender is required!" })} />
                            Female
                        </label>
                        <label>
                            <input type="radio"
                                name="gender" value="M"
                                {...register("gender", { required: "Gender is required!" })} />
                            Male
                        </label>
                    </ContactFieldset>
                    <label htmlFor="message">Message</label>
                    <ContactTextarea type="textarea"
                        name="message" placeholder="Write you're message here (MAX 200 LETTERS)..."
                        {...register("message", {
                            required: "Message is required!",
                            maxLength: 200
                        })} />
                    {errors.message && (<ContactError><small>{errors.message.message}</small></ContactError>)}
                    <ButtonContent>
                        <BtnPrimary type="submit">SEND</BtnPrimary>
                    </ButtonContent>
                </ContactForm>
            </ContactWapper>
        </ContactContainer>
    )
}

export default Contact
