/***********************************************************************
************ Author:    Christian KEMGANG NGUESSOP *********************
************ Version:    1.0.0                      ********************
***********************************************************************/

import { createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../api/apiSlice";

const educationalsAdapter = createEntityAdapter()

const initialState = educationalsAdapter.getInitialState()

export const educationalsApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getEducationals: builder.query({
            query: () => '/educationals',
            transformResponse: responseData => {
                return educationalsAdapter.setAll(initialState, responseData)
            },
            providesTags: (result, error, arg) => [
                { type: "Educationals", id: "DataEducationals" },
                ...result.ids.map(id => ({ type: "Educationals", id }))
            ]
        })
    })
});

export const { useGetEducationalsQuery } = educationalsApiSlice