/***********************************************************************
************ Author:    Christian KEMGANG NGUESSOP *********************
************ Version:    1.0.0                      ********************
***********************************************************************/

import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import {
    ExperienceItems, ExperienceItem, ExperienceContainer,
    ExperienceWrapper, Title, Item, Title2, ItemContent, Close, Open, Items,
} from '../../styles/styledExperiences/Experiences.Styled';
import { useGetProfessionalsQuery } from '../../features/professionals/professionalsSlice';
import { useGetPersonalsQuery } from '../../features/personals/personalsSlice';
import { useGetEducationalsQuery } from '../../features/educationals/educationalsSlice';
import { SpinningCircles, Grid } from 'react-loading-icons';

const Experiences = () => {

    const { professionalId, personalId, educationalId } = useParams()
    let titleProfessional, titlePersonal, titleEducational;

    // *******PROFESSIONALS*******
    const { professional,
        isLoading: isLoadingProfessional,
        isSuccess: isSuccessProfessional,
        isError: isErrorProfessional,
        //error: errorPersonal
    } = useGetProfessionalsQuery('getProfessionals', {
        selectFromResult: ({ data, isLoading, isSuccess, isError }) => ({
            professional: data?.entities[professionalId],
            isLoading,
            isSuccess,
            isError,
        })
    });

    if (isLoadingProfessional) {
        titleProfessional = <><SpinningCircles fill="#2f2360" stroke='#841a1a' height='2em' speed={1} />Loading...</>
    } else if (isSuccessProfessional) {
        titleProfessional = professional?.title;
    } else if (isErrorProfessional) {
        titleProfessional = <><Grid fill="#2f2360" stroke='#841a1a' height='2em' speed={1} />Error!!!</>
    }

    const [openModalProfessional, setOpenModalProfessional] = useState(false);
    const HandlerModalProfessional = () => {
        openModalProfessional ? setOpenModalProfessional(false) : setOpenModalProfessional(true);
    }

    let dataProfessional = professional?.data.map((items, index) => {
        return (
            <Items>
                <Item>⏺ {items.year}</Item>
                {items.descriptions.map((subItems, Indexes) => {
                    return (<>{openModalProfessional && <ItemContent> {subItems.description} </ItemContent>}</>)
                })}
            </Items>
        );
    });

    // *******PERSONALS*******
    const { personal,
        isLoading: isLoadingPersonal,
        isSuccess: isSuccessPersonal,
        isError: isErrorPersonal,
    } = useGetPersonalsQuery('getPersonals', {
        selectFromResult: ({ data, isLoading, isSuccess, isError }) => ({
            personal: data?.entities[personalId],
            isLoading,
            isSuccess,
            isError,
        })
    });

    if (isLoadingPersonal) {
        titlePersonal = <><SpinningCircles fill="#2f2360" stroke='#841a1a' height='2em' speed={1} />Loading...</>
    } else if (isSuccessPersonal) {
        titlePersonal = personal?.title;
    } else if (isErrorPersonal) {
        titlePersonal = <><Grid fill="#2f2360" stroke='#841a1a' height='2em' speed={1} />Error!!!</>
    }

    const [openModalPersonal, setOpenModalPersonal] = useState(false);
    const HandlerModalPersonal = () => {
        openModalPersonal ? setOpenModalPersonal(false) : setOpenModalPersonal(true);
    }

    let dataPersonal = personal?.data.map((items, index) => {
        return (
            <Items>
                <Item>⏺ {items.year}</Item>
                {items.descriptions.map((subItems, Indexes) => {
                    return (<>{openModalPersonal && <ItemContent> {subItems.description} </ItemContent>}</>)
                })}
            </Items>
        );
    });

    // *******EDUCATIONALS*******
    const { educational,
        isLoading: isLoadingEducational,
        isSuccess: isSuccessEducational,
        isError: isErrorEducational,
        //error: errorEducational
    } = useGetEducationalsQuery('getEducationals', {
        selectFromResult: ({ data, isLoading, isSuccess, isError/*, error*/ }) => ({
            educational: data?.entities[educationalId],
            isLoading,
            isSuccess,
            isError,
            //error
        })
    });

    if (isLoadingEducational) {
        titleEducational = <><SpinningCircles fill="#2f2360" stroke='#841a1a' height='2em' speed={1} />Loading...</>
    } else if (isSuccessEducational) {
        titleEducational = educational?.title;
    } else if (isErrorEducational) {
        titleEducational = <><Grid fill="#2f2360" stroke='#841a1a' height='2em' speed={1} />Error!!!</>
    }

    const [openModalEducational, setOpenModalEducational] = useState(false);
    const HandlerModalEducational = () => {
        openModalEducational ? setOpenModalEducational(false) : setOpenModalEducational(true);
    }

    let dataEducational = educational?.data.map((items, index) => {
        return (
            <Items>
                <Item>⏺ {items.year}</Item>
                {items.descriptions.map((subItems, Indexes) => {
                    return (<div>{openModalEducational && <ItemContent> {subItems.description} </ItemContent>}</div>)
                })}
            </Items>
        );
    });

    return (
        <ExperienceContainer>
            <ExperienceWrapper>
                <Title>Experiences</Title>
            </ExperienceWrapper>
            <ExperienceItems>
                <ExperienceItem>
                    <Title2>{titleProfessional}</Title2>
                    <div onClick={HandlerModalProfessional}>
                        {openModalProfessional ? <Close>close</Close> : <Open >show</Open>}
                    </div>
                    {dataProfessional}
                </ExperienceItem>
                <ExperienceItem>
                    <Title2>{titlePersonal}</Title2>
                    <div onClick={HandlerModalPersonal}>
                        {openModalPersonal ? <Close>close</Close> : <Open >show</Open>}
                    </div>
                    {dataPersonal}
                </ExperienceItem>
                <ExperienceItem>
                    <Title2>{titleEducational}</Title2>
                    <div onClick={HandlerModalEducational}>
                        {openModalEducational ? <Close>close</Close> : <Open >show</Open>}
                    </div>
                    {dataEducational}
                </ExperienceItem>
            </ExperienceItems>
        </ExperienceContainer>
    )
}

export default Experiences;
